<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.ENVIRONMENTAEXPORT"
              :innerAPI="this.proApis.ENVIRONMENTAREAIMPORT"
              :API="this.proApis.ENVIRONMENTAREAIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
            <!-- <el-select v-model="model" placeholder="工厂" size="small" style="width:100px !important;marginRight: 5px;">
                <el-option label="一分厂" value="0">一分厂</el-option>
                <el-option label="二分厂" value="1">二分厂</el-option>
            </el-select> -->
            <el-select clearable v-model="departmentId" filterable :placeholder="$t('WORK CENTER')"  size="small" style="width:120px !important;marginRight: 5px;">
                <el-option v-for="item in workList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select clearable v-model="processName" filterable :placeholder="$t('WORKING PROCEDURE')" size="small" style="width:120px !important;marginRight: 5px;">
                <el-option v-for="item in wkln" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-input v-model="nameOrCode" :placeholder="$t('AREA NAME OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <!-- <template slot="menu"> -->
            <!-- <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">禁用</el-button> -->
        <!-- </template> -->
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,changeStatus,add,edit,remove,wklnList} from "@/api/sim"
import importBtn from "@/components/importExport/importBtn";
import {departmentListSelect} from "@/api/config";
export default {
  components:{
    importBtn
  },
    data() {
        return {
            tableLoading: false,
            workList: [],
            departmentId: "",
            wkln: [],
            //工序
            processName: "",
            nameOrCode: "",
            status: "0",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                columnBtn: false,
                labelWidth: 110,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('NAME'),
                    prop: "name",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('CODE'),
                    prop: "code",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE CODE'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('WORK CENTER'),
                    display: false,
                    prop: "departmentName"
                }, {
                    label: this.$t('WORK CENTER'),
                    type: "select",
                    hide: true,
                    prop: "departmentId",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    dicData: []
                }, {
                    label: this.$t('SET TEMPERATURE'),
                    value: 0,
                    type: "number",
                    prop: "settingTemperature",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE SET TEMPERATURE'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('TEMPERATURE DEVIATION VALUE'),
                    value: 0,
                    type: "number",
                    prop: "temperatureDeviation",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE TEMPERATURE DEVIATION VALUE'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('SET HUMIDITY%'),
                    value: 0,
                    type: "number",
                    prop: "settingHumidity",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE SET HUMIDITY%'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('HUMIDITY DEVIATION%'),
                    value: 0,
                    type: "number",
                    prop: "humidityDeviation",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE SET HUMIDITY%'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('WORKING PROCEDURE'),
                    prop: "processId",
                    type: "select",
                    dicData: [],
                    hide: true,
                    props: {
                        label: "name",
                        value: "id"
                    },
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE WORKING PROCEDURE'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('WORKING PROCEDURE'),
                    prop: "processName",
                    type: "select",
                    display: false,
                    props: {
                        label: "name",
                        value: "id"
                    }
                }, {
                    label: this.$t('CREATOR'),
                    addDisplay: false,
                    editDisplay: false,
                    prop: "createUserName"
                }, {
                    label: this.$t('CREATION TIME'),
                    width: 130,
                    addDisplay: false,
                    editDisplay: false,
                    prop: "createTime"
                }]
            }
        }
    },
    created() {
      departmentListSelect().then(res => {
        this.workList = res.data.data;
        this.tableOption.column[3].dicData = res.data.data;
      });
      wklnList().then(res => {
        this.tableOption.column[8].dicData = res.data.data.items;
        this.wkln = res.data.data.items;
      })
      this.list();
    },
    methods: {
      refreshData() {
        this.list();
      },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then((res) => {
                if(res.data.code=="0000"){
                    this.$message({
                    message: "删除成功",
                    type: "success"
                     });
                }
                    this.list();
                })
            })
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        handleUpdate(row,index,done) {
            edit(row).then((res) => {
                if(res.data.code=="0000"){
                    this.$message({
                    message: "修改成功",
                    type: "success"
                     });
                }
                this.list();
                done();
            })
        },
        handleSave(row,done) {
            console.log(row);
            add(row).then((res) => {
                if(res.data.code=="0000"){
                    this.$message({
                    message: "新增成功",
                    type: "success"
                     });
                }
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.page.pageCurrent,this.page.pageSize,this.nameOrCode,this.processName,this.departmentId).then(res => {
              this.page.total = res.data.data.total;
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>