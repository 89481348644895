import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,nameOrCode,processId,departmentId="1") => {
    return request({
        url : businessUrl + "environmentArea/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode,processId,departmentId
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "environmentArea/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "environmentArea/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "environmentArea/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            status: 1
        }
    })
}
//岗位分类
export const typeList = () => {
    return request({
        url : businessUrl + "dict/selectDictByCategoryId",
        method: "get",
        params: {
            categoryId : "05b4b2a3d01a470eb47eae52fd7395bf"
        }
    })
}
export const wklnList = () => {
    return request({
        url : businessUrl + "process/selectPage",
        method: "post",
        data: {
        }
    })
}